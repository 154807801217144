<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-icon left>mdi-book-open-page-variant</v-icon>
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Contact Directory ({{ totalContacts }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'hub.contact-directory.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Contact
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="contact-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search name, title or phone number"
          @input="search"
        ></v-text-field>
      </div>
      <v-data-table
        :headers="headers"
        :items="contacts"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        @click:row="editItem"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalContacts"
        @update:options="fetch"
      >
        <template v-slot:item.name="{ item }">
          <user-avatar :avatar="item.avatar" size="40" class="mr-4" />
          {{ item.name }}
        </template>
        <template v-slot:item.building="{ item }">
          <div class="text-truncate" style="max-width: 200px">
            {{ item.buildings.map((building) => building.name).join(', ') }}
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(contacts.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Contact list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import UserAvatar from '@/components/UserAvatar'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'ContactsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    UserAvatar,
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'name',
      sortOrder: true,
      headers: [
        {
          text: 'Name',
          value: 'name',
          width: '17%',
        },
        {
          text: 'Title',
          value: 'title',
          width: '15%',
        },
        {
          text: 'Buildings',
          value: 'building',
          width: '10%',
        },
        {
          text: 'Mobile Number',
          value: 'phone_number',
          width: '15%',
        },
        {
          text: 'Telephone number',
          value: 'telephone_number',
          width: '15%',
        },
        {
          text: 'Email',
          value: 'email',
          width: '15%',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '13%',
        },
      ],
      selected: [],
    }
  },

  created() {
    this.clearContacts()
    this.fetch(1)
  },

  computed: {
    ...mapState({
      contacts: (state) => state.contact.list,
      listMeta: (state) => state.contact.listMeta,
      permissions: (state) => state.auth.permissions,
      totalContacts: (state) => state.contact.totalContacts,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.CONTACTS_CREATE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getContacts: 'contact/getContacts',
    }),

    ...mapMutations({
      clearContacts: 'contact/clearContactList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
    }),

    filterList(filterValue) {
      this.clearContacts()
      this.fetch()
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearContacts()
      this.fetch()
    }, 600),

    async fetch(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalContacts
            : options.itemsPerPage
          : 25,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getContacts(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    getSortParam() {
      return this.sortOrder ? `${this.defaultFilter}` : `-${this.defaultFilter}`
    },

    editItem(item) {
      this.$router.push({
        name: 'hub.contact-directory.details',
        params: { id: item.id },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
</style>
